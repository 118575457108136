import style from './index.module.scss';

const Footer = () => {

  return (
    <div className={style.footer}>
      <p>2023 © Fake Credit Card </p>
    </div>
  )
}

export default Footer;
