import style from './index.module.scss';

const Header = () => {
  
  return (
  <div className={style.header}>
    <div>Fake Credit Card</div>
  </div>
  )
};

export default Header;
